import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import AutoCompleteList from './AutoCompleteList';
import classNames from 'classnames';
import { SearchIcon } from 'src/components/SearchIcon';
import { connect } from 'react-redux';
import {
	getCurrentQuery,
	getIsFixedSearchBar,
	getIsLoadingProducts,
	getOpenSearchBar,
	resetOpenSearchBar,
	setIsFilter,
} from 'src/lib/redux/modules/search';
import withResponsive from 'src/lib/hoc/withResponsive';
import withParsedQuery from 'src/lib/hoc/withParsedQuery';
import TrackingManager from 'src/lib/managers/TrackingManager';
import debug from 'src/lib/logger';
import { FILTERS, IS_DEBUG, MAXIMUM_INDEX, SEARCH_BAR_INDEX } from 'src/lib/constants/general';
import { deleteKeysFromObj } from 'src/lib/utils';
import { CloseIcon } from '../Shared/Shared';
import { withRouter } from 'next/router';
import withDictionary from 'src/lib/hoc/withDictionary';
import QueryManager from 'src/lib/managers/QueryManager';
import { SEARCH } from 'src/lib/constants/routes';
import { ScrollEvent } from '../ScrollEvent/ScrollEvent';

const DEFAULT_SEARCH_PLACEHOLDER = 'What are you looking for?';
const prefixStyles = 'SB_';

@connect(
	state => ({
		isFixedSearchBar: getIsFixedSearchBar(state.search),
		currentQuery: getCurrentQuery(state.search),
		isLoadingProducts: getIsLoadingProducts(state.search),
		openSearchBar: getOpenSearchBar(state.search),
	}),
	dispatch => ({
		dispatch,
	})
)
@withParsedQuery()
@withResponsive()
@withDictionary()
class SearchBar extends PureComponent {
	static propTypes = {
		ignoreUrlQ: PropTypes.bool,
		ignoreQ: PropTypes.bool,
		submitQuery: PropTypes.func,
		isFixedSearchBar: PropTypes.bool,
		openSearchBar: PropTypes.bool,
		isLoadingProducts: PropTypes.bool,
		searchFormInnerClass: PropTypes.string,
		searchText: PropTypes.string,
		wrapperClassName: PropTypes.string,
		dispatch: PropTypes.func.isRequired,
		responsive: PropTypes.object.isRequired,
		parsedQuery: PropTypes.object,
		searchBarInnerStyles: PropTypes.object,
		searchBarStyles: PropTypes.object,
		afterSubmit: PropTypes.func,
		currentQuery: PropTypes.string,
		placeholder: PropTypes.string,
	};

	static defaultProps = {
		ignoreUrlQ: false,
		ignoreQ: false,
		submitQuery: null,
		isFixedSearchBar: false,
		isLoadingProducts: false,
		openSearchBar: false,
		searchFormInnerClass: '',
		searchText: '',
		wrapperClassName: '',
		parsedQuery: {},
		searchBarInnerStyles: {},
		searchBarStyles: {},
		afterSubmit: null,
		currentQuery: '',
		placeholder: '',
	};

	constructor(props) {
		super(props);
		const { currentQuery } = this.props;
		this.inputRef = React.createRef();
		this.autocompleteRef = React.createRef();

		const fromQ = this.props.ignoreUrlQ ? false : this.props.parsedQuery.q;
		let stateQuery = currentQuery || fromQ || '';
		if (this.props.ignoreQ) {
			stateQuery = '';
		}
		this.state = {
			stateQuery,
			inputFocus: false,
			currentFocus: 0,
		};
	}

	isTouchedAutocomplete = event => this.autocompleteRef.current && this.autocompleteRef.current.contains(event.target);

	isTouchedInput = event => this.inputRef.current && this.inputRef.current.contains(event.target);

	listener = event => {
		// Do nothing if clicking ref's element or descendent elements
		if (this.isTouchedAutocomplete(event) || this.isTouchedInput(event)) {
			return;
		}
		this.handleInputBlur();
	};

	componentDidMount() {
		document.addEventListener('mousedown', this.listener);
		document.addEventListener('touchstart', this.listener);
	}

	componentDidUpdate(prevProps) {
		if (this.props.currentQuery !== prevProps.currentQuery) {
			this.setState({
				stateQuery: this.props.currentQuery,
			});
		}

		if (this.props.openSearchBar) {
			this.inputRef && this.inputRef.current && this.inputRef.current.focus();
			this.props.dispatch(resetOpenSearchBar());
		}
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.listener);
		document.removeEventListener('touchstart', this.listener);
	}

	handleChange = event => {
		this.setState({ stateQuery: event.target.value });
	};

	handleSearchBarClick = () => {
		this.inputRef && this.inputRef.current && this.inputRef.current.focus();
	};

	handleInputIsFocus = () => {
		this.setState({ inputFocus: true });
	};

	handleInputBlur = () => {
		this.setState({ inputFocus: false });
	};

	onSubmit = (event, inputValue) => {
		const { parsedQuery, beforeSubmit, submitQuery, dispatch, fromTest = false } = this.props;

		if (fromTest) {
			delete parsedQuery.vendors;
		}

		delete parsedQuery.zby;
		event.preventDefault();

		debug('onSubmit', inputValue);
		if (!inputValue) {
			return;
		}

		dispatch(setIsFilter(false));

		// need to re decode to display it as "&" and not "%26", and replace empty %'s with %25 - otherwise it'll crash.
		const decodedInput = decodeURIComponent(
			inputValue
				.replace(/\s+/g, ' ')
				.trim()
				.replace(/&/, '%26')
				.replace(/(%\D)|(%$)/, '%25')
		);
		this.setState({
			stateQuery: decodedInput,
		});
		this.inputRef.current.blur();

		if (beforeSubmit) {
			beforeSubmit(inputValue);
		}

		if (submitQuery) {
			return submitQuery(inputValue);
		}

		const queryParams = { ...deleteKeysFromObj([...FILTERS, 'vprops'], parsedQuery), q: decodedInput };
		delete queryParams.page;
		const newUrl = `${location.origin}${SEARCH}?${QueryManager.stringify(queryParams)}`;
		location.href = newUrl;
	};

	renderAutoCompleteList() {
		const { dictionary, parsedQuery } = this.props;
		const { stateQuery } = this.state;
		return (
			<AutoCompleteList searchQuery={stateQuery}>
				{({ loading, error, data = [], onlyOneChar = false }) => {
					if (onlyOneChar) {
						return <li disabled>{dictionary('You have to enter a search query')}</li>;
					}

					if (loading) {
						return <li disabled>{dictionary('Loading...')}</li>;
					}

					if (error) {
						return (
							<li disabled>
								{dictionary('Error!')} {error}
							</li>
						);
					}

					if (!data.length) {
						return <li disabled>{dictionary('No results found')}</li>;
					}

					return data.slice(0, MAXIMUM_INDEX).map((item, index) => {
						const startIndex = item.toLowerCase().indexOf(stateQuery.toLowerCase());

						return (
							<li
								key={item.replace(' ', '-')}
								className={this.state.currentFocus - 1 === index ? 'ac-active' : null}
								onClick={event => {
									if (item) {
										TrackingManager.trackOnNav('Autocomplete Click', {
											keyword: item,
											cid: parsedQuery.cid,
											action: 'Autocomplete Click',
										});
										this.onSubmit(event, item);
									}
								}}>
								{startIndex === 0 ? (
									<Fragment>
										<span>{item.substr(0, stateQuery.length)}</span>
										<strong>{item.substr(stateQuery.length)}</strong>
									</Fragment>
								) : (
									item
								)}
							</li>
						);
					});
				}}
			</AutoCompleteList>
		);
	}

	handleKeydown(event) {
		const { stateQuery, currentFocus } = this.state;
		const { parsedQuery } = this.props;

		if (event.key === 'Enter' && stateQuery) {
			// submitig the focued query
			if (currentFocus === SEARCH_BAR_INDEX) {
				TrackingManager.trackOnNav('search', {
					keyword: stateQuery,
					cid: parsedQuery.cid,
					action: 'search',
				});
				this.onSubmit(event, stateQuery);
			} else {
				this.autocompleteRef.current.children[currentFocus - 1].click();
			}
		} else if (
			event.key === 'ArrowDown' &&
			currentFocus < MAXIMUM_INDEX &&
			currentFocus < this.autocompleteRef.current.children.length
		) {
			event.preventDefault(); //change behavior of moving the caret
			this.setState({ currentFocus: currentFocus + 1 });
		} else if (event.key === 'ArrowUp') {
			event.preventDefault();
			if (currentFocus > SEARCH_BAR_INDEX) {
				this.setState({ currentFocus: currentFocus - 1 });
			} else if (currentFocus === SEARCH_BAR_INDEX) {
				// allows user to focus last item when focusing the search bar
				this.setState({ currentFocus: this.autocompleteRef.current.children.length });
			}
		} else if ((event.key === 'ArrowRight' || event.key === 'ArrowLeft') && currentFocus !== SEARCH_BAR_INDEX) {
			// user can only move the carret when focusing the search bar
			event.preventDefault();
		} else {
			this.setState({ currentFocus: SEARCH_BAR_INDEX });
		}
	}

	botButton = event => {
		let product = 'shower curtains';
		if (window.faker) {
			product = window.faker.commerce.product();
		}
		this.onSubmit(event, product);
	};

	clearInput = () => {
		this.setState({ stateQuery: '' });
	};

	onScrollUp = () => {
		document.body.classList.add('scrolledUp');
	}

	onScrollDown = () => {
		if (this.state.stateQuery) {
			setTimeout(() => this.clearInput());
		}
		document.body.classList.remove('scrolledUp');	
	}

	render = () => {
		const {
			isFixedSearchBar,
			isLoadingProducts,
			searchText,
			wrapperClassName,
			searchFormInnerClass,
			responsive,
			searchBarInnerStyles,
			searchBarStyles,
			dictionary,
			placeholder,
			parsedQuery,
		} = this.props;
		const { stateQuery, inputFocus } = this.state;
		const isLaptop = responsive && responsive.isLaptop;

		return (
			<ScrollEvent onScrollDown={this.onScrollDown} onScrollUp={this.onScrollUp}>
				<div className={classNames(prefixStyles + 'search-bar-wrapper', wrapperClassName)}>
					<div
						className={classNames(
							prefixStyles + 'search-bar',
							!isLaptop && isFixedSearchBar ? prefixStyles + 'fixed-search-bar' : ''
						)}
						style={searchBarStyles}>
						<div className={prefixStyles + 'search-bar-inner'} style={searchBarInnerStyles}>
							<div className={classNames(prefixStyles + 'search-form', inputFocus ? '' : prefixStyles + 'collapsed')}>
								<div
									className={classNames(prefixStyles + 'search-form-inner', searchFormInnerClass)}
									suppressHydrationWarning>
									<input
										suppressHydrationWarning
										data-search-input="1"
										ref={this.inputRef}
										className={classNames(
											prefixStyles + 'search-input',
											isLoadingProducts && stateQuery ? prefixStyles + 'loading' : '',
											!inputFocus ? prefixStyles + 'proper-case' : ''
										)}
										placeholder={dictionary(placeholder || DEFAULT_SEARCH_PLACEHOLDER)}
										onBlur={event => event.preventDefault() && this.handleInputBlur()}
										onChange={this.handleChange}
										value={stateQuery}
										onKeyDown={event => {
											this.handleKeydown(event);
										}}
										onFocus={() => {
											TrackingManager.trackEvent('Open Search Bar Menu');
											this.handleInputIsFocus();
										}}
									/>
									{stateQuery ? (
										<div data-search-delete="1" className={prefixStyles + 'clear-input'} onClick={this.clearInput}>
											<CloseIcon />
										</div>
									) : null}
									{IS_DEBUG ? <div onClick={this.botButton} className={prefixStyles + 'bot'}></div> : null}
									<button
										className={prefixStyles + 'search-icon'}
										data-search-button="1"
										onClick={event => {
											if (!stateQuery) {
												this.handleInputIsFocus();
												return;
											}
											TrackingManager.trackOnNav('search', {
												keyword: stateQuery,
												cid: parsedQuery.cid,
												action: 'search',
											});
											this.onSubmit(event, stateQuery);
										}}>
										{searchText || <SearchIcon className={prefixStyles + 'search-icon-img'} />}
									</button>
								</div>
							</div>

							{inputFocus ? (
								<ul ref={this.autocompleteRef} className={prefixStyles + 'autocomplete-list'}>
									{(() => {
										if (!stateQuery) {
											return <li disabled>{dictionary('Type anything to search')}</li>;
										}
										return this.renderAutoCompleteList();
									})()}
								</ul>
							) : null}
						</div>
					</div>
				</div>
			</ScrollEvent>
		);
	};
}

export default withRouter(SearchBar);
