import React from 'react';

export const CloseIcon = () => {
	return (
		<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M15 13.2225L21.2225 7L23 8.7775L16.7775 15L23 21.2225L21.2225 23L15 16.7775L8.7775 23L7 21.2225L13.2225 15L7 8.7775L8.7775 7L15 13.2225Z"
				fill="black"
			/>
		</svg>
	);
};

export const HalfStar = ({ color = 'black', className = '' }) => {
	return (
		<svg
			fill={color}
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			enableBackground="new 0 0 24 24"
			viewBox="0 0 24 24"
			width="18px"
			height="18px">
			<g>
				<rect fill="none" height="24" width="24" x="0" />
			</g>
			<g>
				<g>
					<g>
						<path d="M22,9.24l-7.19-0.62L12,2L9.19,8.63L2,9.24l5.46,4.73L5.82,21L12,17.27L18.18,21l-1.63-7.03L22,9.24z M12,15.4V6.1 l1.71,4.04l4.38,0.38l-3.32,2.88l1,4.28L12,15.4z" />
					</g>
				</g>
			</g>
		</svg>
	);
};

export const FullStar = ({ color = 'black', className = '' }) => {
	return (
		<svg fill={color} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18px" height="18px">
			<path d="M0 0h24v24H0z" fill="none" />
			<path d="M0 0h24v24H0z" fill="none" />
			<path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
		</svg>
	);
};
