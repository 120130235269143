import React from 'react';
import { fetchAutoCompleteData } from 'src/lib/api';
import { debounce } from 'src/lib/utils';

class AutoCompleteList extends React.Component {

    state = {
        data: undefined,
        loading: false,
        onlyOneChar: false,
    };

    componentDidUpdate = prevProps => {
        const { searchQuery } = this.props;
        if (searchQuery !== prevProps.searchQuery) {
            this.setState({
                loading: true,
                onlyOneChar: false,
            });
            this.setSearchQuery();
        }
    };

    setSearchQuery = debounce(() => {
        this.setupAutoComplete();
    }, 200);

    componentDidMount = () => {
        this.ignoreFetch = false;

        const { searchQuery } = this.props;
        this.setState({
            onlyOneChar: searchQuery.length === 1,
        });
        this.setupAutoComplete();
    };

    componentWillUnmount() {
        this.ignoreFetch = true;
    }

    setupAutoComplete = () => {
        const { searchQuery } = this.props;
        if (searchQuery.length === 1) {
            if (!this.ignoreFetch) {
                this.setState({
                    onlyOneChar: true,
                });
            }
            return;
        }
        if (!this.ignoreFetch) {
            this.setState({ loading: true });
        }

        fetchAutoCompleteData(searchQuery)
            .then(response => {
                if (!this.ignoreFetch)
                    this.setState({
                        data: response,
                        loading: false,
                        onlyOneChar: false,
                    });
            })
            .catch(error => {
                if (!this.ignoreFetch)
                    this.setState({
                        loading: false,
                    });
                console.log('error', error.message);
            });
    };

    render = () => {
        const { children } = this.props;
        const { data, loading, error, onlyOneChar } = this.state;

        return children({
            data,
            loading,
            error,
            onlyOneChar,
        });
    };
}

export default AutoCompleteList;
