import { useState } from 'react';
import { useSelector } from 'react-redux';
import { SB_SCROLL } from 'src/lib/constants/tests';
import useResponsive from 'src/lib/hooks/useResponsive';
import { useScrollDirection } from 'src/lib/hooks/useScrollDirection';
import TestsManager from 'src/lib/managers/TestsManager';
import { getIsBackToTop } from 'src/lib/redux/modules/settings';

export const ScrollEvent = props => {
	const { children, onScrollDown, onScrollUp } = props;
	const scrollDirection = useScrollDirection();
	const isBackToTop = useSelector(state => getIsBackToTop(state.settings));
	const responsive = useResponsive();
	const [hasScrolledClass, setHasScrolledClass] = useState(false);
	
	if (TestsManager.isInTestGroup(SB_SCROLL, 1)) {
		if (scrollDirection === 'up' && responsive.isMobile && !hasScrolledClass && !isBackToTop) {
			onScrollUp();
			setHasScrolledClass(true);
		}
		
		if (scrollDirection === 'down' && hasScrolledClass) {
			onScrollDown();
			setHasScrolledClass(false);
		}
	}

	return children;
};
